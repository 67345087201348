import React from "react";
import { ArrowUpRight } from "~/components/icons";
import Link from "next/link";
import StrapiBlocksRenderer from "~/components/reusables/strapi-blocks-renderer";
import Image from "next/image";
import AspectRatio from "components/aspect-ratio";
import { Button } from "~/components/ui/button";

const NavBanner = ({ item }) => {
  return (
    <div
      className={ "bg-gradient-blue-purple absolute bottom-0 z-50 h-20 w-full"}
    >
      <div className="flex h-full items-center justify-between gap-3 px-3 md:justify-end md:gap-6 lg:px-12 lg:container">
        {item.bannerImg.data && (
          <div className="w-[178px]">
            <AspectRatio
              aspectRatio={{
                width: item.bannerImg.data.attributes.width,
                height: item.bannerImg.data.attributes.height,
              }}
            >
              <Image
                unoptimized
                src={item.bannerImg.data.attributes.url}
                fill
                alt="Outshift"
              />
            </AspectRatio>
          </div>
        )}
        {item.bannerTitle && (
          <div className="">
            <StrapiBlocksRenderer content={item.bannerTitle} />
          </div>
        )}
        {item.bannerDescription && (
          <div className="max-w-[211px] md:max-w-[270px]">
            <StrapiBlocksRenderer content={item.bannerDescription} />
          </div>
        )}
        {item.bannerBtn && (
          <Link
            href={item.bannerBtn.url}
            className={item.bannerBtn.variant == "icon" ? "" : "hidden md:flex"}
          >
            {item.bannerBtn.variant == "icon" ? (
              <div className="rounded-md border border-white text-white">
                <ArrowUpRight size={30} color="white" />
              </div>
            ) : (
              <Button variant="outline" className="hidden md:flex">
                {item.bannerBtn.cta}
              </Button>
            )}
          </Link>
        )}
      </div>
    </div>
  );
};
export default NavBanner;
