"use client";
import React, { useState } from "react";
import { NavigationMenu } from "~/components/ui/navigation-menu";
import Link from "next/link";
import Image from "next/image";
import MobileNav from "./mobile-nav";
import DesktopNav from "./desktop-nav";
import AspectRatioComponent from "components/aspect-ratio";
import { useWindowSize } from "@uidotdev/usehooks";

const NavMenuClient = ({ logo, NavDropdowns, eyeBrowLink }) => {
  const size = useWindowSize();
  const [open, setOpen] = useState(false);
  return (
    <NavigationMenu
      onValueChange={(value) => (value ? setOpen(true) : setOpen(false))}
    >
      <div className="relative z-[10000] w-screen bg-black">
        <div className="container flex items-center justify-between md:gap-8 lg:grid lg:grid-cols-2 lg:items-end lg:gap-12">
          <Link href="/" className="relative w-[160px] px-0 py-4 md:w-[200px]">
            <AspectRatioComponent aspectRatio={{ width: 200, height: 83 }}>
              <Image
                unoptimized
                src={logo}
                alt="Outshift Logo"
                fill
                className="w-full"
              />
            </AspectRatioComponent>
          </Link>
          <div>
            {size.width && size.width < 1024 ? (
              <div className="block lg:hidden">
                <MobileNav menuItems={NavDropdowns} eyeBrowLink={eyeBrowLink} />
              </div>
            ) : (
              <div className="hidden lg:flex lg:gap-2 items-end w-full justify-end">
                <DesktopNav
                  menuItems={NavDropdowns}
                  eyeBrowLink={eyeBrowLink}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {open && (
        <div className="fixed inset-0 -z-10 h-screen w-full bg-slate-950/30 backdrop-blur-sm"></div>
      )}
    </NavigationMenu>
  );
};

export default NavMenuClient;
