"use client";
import {
  BlocksRenderer,
  type BlocksContent,
} from "@strapi/blocks-react-renderer";
import Link from "next/link";

const StrapiBlocksRenderer = ({ content }: { content: BlocksContent }) => {
  return (
    <BlocksRenderer
      content={content}
      blocks={{
        paragraph: ({ children }) => <p className="text-[15px] md:font-p">{children}</p>,
        heading: ({ children, level }) => {
          switch (level) {
            case 1:
              return <h1 className="font-h1">{children}</h1>;
            case 2:
              return <h2 className="font-h2">{children}</h2>;
            case 3:
              return <h3 className="font-h3">{children}</h3>;
            case 4:
              return <h4 className="font-h4">{children}</h4>;
            case 5:
              return <h5 className="font-h5">{children}</h5>;
            case 6:
              return <h6 className="font-h6">{children}</h6>;
            default:
              return <h5 className="font-h5">{children}</h5>;
          }
        },
        link: ({ children, url }) => <Link href={url}>{children}</Link>,
      }}
      modifiers={{
        bold: ({ children }) => <strong>{children}</strong>,
        italic: ({ children }) => <span className="italic">{children}</span>,
      }}
    />
  );
};

export default StrapiBlocksRenderer;
