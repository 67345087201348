import React from "react";
import { ArrowUpRight } from "~/components/icons";
import Link from "next/link";
import StrapiBlocksRenderer from "~/components/reusables/strapi-blocks-renderer";

const MenuCTAItem = ({ cta }) => {
  return (
    <div className="rounded-md border border-[#666666] p-4">
      <Link href={cta.url}>
        <div className="flex flex-col md:flex-row items-start md:items-center justify-between gap-2">
          {cta.title && (
            <div className="flex-[.5]">
              <StrapiBlocksRenderer content={cta.title} />
            </div>
          )}
          {cta.description && (
            <div className="flex-[.55]">
              <StrapiBlocksRenderer content={cta.description} />
            </div>
          )}
          <div className="">
            <div className="block">
              <div className="block md:hidden rounded-md border border-white text-white">
                <ArrowUpRight size={30} color="white" />
              </div>
              <div className="hidden md:block rounded-md border border-white text-white">
                <ArrowUpRight size={40} color="white" />
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};
export default MenuCTAItem;
