import Image from "next/image";
import Link from "next/link";
import { AspectRatio } from "../ui/aspect-ratio";
import TopicTag from "./topic-tag";
import { cn } from "~/lib/utils";
function simpleHash(str) {
  let hash = 0;
  for (let i = 0; i < str?.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash; // Convert to 32bit integer
  }
  // Ensure the result is a positive number within 0-99
  return Math.abs(hash) % 100;
}
export const getFeatureImage = (title, key) => {
  const id = simpleHash(key);
  if (title === "Insights") {
    const insightsImgs = [
      "https://outshift-headless-cms-s3.s3.us-east-2.amazonaws.com/INSIGHTS_1.png",
      "https://outshift-headless-cms-s3.s3.us-east-2.amazonaws.com/INSIGHTS_2.png",
      "https://outshift-headless-cms-s3.s3.us-east-2.amazonaws.com/INSIGHTS_3.png",
      "https://outshift-headless-cms-s3.s3.us-east-2.amazonaws.com/INSIGHTS_4.png",
    ];
    return insightsImgs[id % insightsImgs.length];
  } else if (title === "Collaborations") {
    const collaborationsImgs = [
      "https://outshift-headless-cms-s3.s3.us-east-2.amazonaws.com/COLLABORATIONS_1.png",
    ];
    return collaborationsImgs[id % collaborationsImgs.length];
  } else if (title === "Inside Outshift") {
    const insideImgs = [
      "https://outshift-headless-cms-s3.s3.us-east-2.amazonaws.com/INSIDEOUTSHIFT_1.png",
      "https://outshift-headless-cms-s3.s3.us-east-2.amazonaws.com/INSIDEOUTSHIFT_2.png",
    ];
    return insideImgs[id % insideImgs.length];
  } else if (title === "Product") {
    const productImgs = [
      "https://outshift-headless-cms-s3.s3.us-east-2.amazonaws.com/PRODUCT_1.png",
      "https://outshift-headless-cms-s3.s3.us-east-2.amazonaws.com/PRODUCT_2.png",
    ];
    return productImgs[id % productImgs.length];
  } else {
    return "https://outshift-headless-cms-s3.s3.us-east-2.amazonaws.com/insights.png";
  }
};
const BlogCard = ({ blog, size, forceHeight = "null" }) => {
  const { attributes, id } = blog;
  const blogImage = attributes?.thumbnail?.data?.attributes?.url;
  const title = attributes?.blog_category?.data?.attributes?.title;

  return (
    <div className="flex flex-col gap-5">
      <Link href={`/blog/${attributes?.key}`} className="group">
        <div
          className={cn(
            `relative mx-auto ${size == "menu" ? "aspect-[15/7] " : "aspect-video md:min-h-[180px]"} overflow-hidden rounded-xl`,
            forceHeight,
          )}
        >
          {/* <div className="h-[450px]"> */}
          <div className="">
            <AspectRatio ratio={size == "menu" ? (300 / 140) : (400 / 220)}>
              <Image
                unoptimized
                src={blogImage || getFeatureImage(title, attributes?.key)}
                fill
                alt="Featured home blog"
                className="w-[90%] rounded-xl object-cover transition-all duration-700 group-hover:scale-105"
                sizes="100%"
              />
            </AspectRatio>
          </div>
        </div>
        {attributes?.blog_category?.data && size !== "menu" && (
          <div className="mb-1 flex gap-3 py-6">
            <Image
              unoptimized
              src={
                attributes?.blog_category?.data?.attributes?.icon?.data
                  ?.attributes.url
              }
              alt="Icon"
              width="25"
              height="25"
            />
            <h6 className="font-h6 uppercase">
              {attributes?.blog_category?.data?.attributes?.title}
            </h6>
          </div>
        )}
        <h3
          className={
            size == "menu"
              ? "font-p pt-2 text-[#A6A6A6]"
              : size == "lg"
                ? "font-h3 mb-5"
                : "font-h2 md:font-h5  lg:text-[26px]"
          }
        >
          {attributes?.title}
        </h3>
      </Link>
      <div className="bottom-0 flex flex-wrap gap-5 md:absolute md:pt-14">
        {attributes?.blog_topics &&
          size !== "menu" &&
          attributes?.blog_topics.data.map((t, i) => <TopicTag topic={t} key={i} />)}
      </div>
    </div>
  );
};

export default BlogCard;
