import React from "react";
import Link from "next/link";
import { ArrowDownRight } from "~/components/icons";

const MenuItem = ({ title, items , className = '' }) => {
  return (
    <div className={className}>
      {title && (
        <div>
          <div className="flex items-center gap-3">
            <div className="hidden lg:block w-6">
              <ArrowDownRight />
            </div>
            <h5 className="text-[18px] md:font-p xl:text-[17px] text-nowrap text-sky-blue lg:text-white">{title}</h5>
          </div>
          <div className="my-5 h-[1px] w-full bg-[#04BBE9]"></div>
        </div>
      )}
      {items &&
        items.map((menu, i) => (
          <Link href={menu.url} target={menu.internal ? '_self' : "_blank"} key={i} className="my-3 flex gap-3">
            <div className="hidden lg:block h-1 w-6"></div>
            <p className="font-p xl:text-[17px] underline-offset-2 hover:underline">
              {menu.text}
            </p>
          </Link>
        ))}
    </div>
  );
};
export default MenuItem;
